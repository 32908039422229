import React, { useState } from 'react';
import Loader from 'react-loader-spinner';

import { colors } from '../utils'
import Nuage1 from '../svg/nuage1.svg';
import Nuage2 from '../svg/nuage2.svg';
import Nuage4 from '../svg/nuage4.svg';
import OmbreMec from '../svg/ombreMEC.svg';
import OmbreMeuf from '../svg/ombreMEUF.svg';
import Fond09 from '../svg/fond_09.svg';

export default ({ dims }) => {

  const [ loading, setLoading ] = useState(false);
  const [ firstname, setFirstname ] = useState('');
  const [ lastname, setLastname ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ message, setMessage ] = useState('');

  const submit = async () => {
    if (!firstname || !lastname || !email || !message) return
    try {
      setLoading(true);
      await fetch('https://api.expliquemoipythagore.fr/v2/public/message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstname,
          lastname,
          email,
          message
        })
      });
      setTimeout(() => {
        setLoading(false);
        alert('Merci ! Nous avons bien reçu votre message et reviendrons vers vous dans les meilleurs délais');
      }, 1000)
    } catch (err) {
      console.error('Error sending message', err);
      alert('Erreur d\'envoi du message... Vous pouvez sinon nous contacter via nos coordonnées en bas de la page')
    }
  }

  return (
    <div style={{ width: '100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: dims.x > 1200 ? -178 : 20, backgroundColor: dims.x > 1200 ? 'transparent' : '#D9E9F4' }}>
      <div style={{ width: dims.x > 1200 ? 750 : '90%', position: 'relative', display: 'flex', flexDirection: 'column', marginTop: dims.x > 1200 ? 350 : 50, alignItems: dims.x > 1200 ? 'flex-start' : 'center' }}>
        <h2 style={{ fontSize: dims.x > 1200 ? 58.16 : 30, color: colors.dark_blue, fontFamily: 'poppins', marginBottom: 50 }}>CONTACT</h2>
        <form style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: dims.x > 1200 ? 'flex-end' : 'center' }}>
          <div style={{ width: '100%', display: 'flex', flexDirection: dims.x > 1200 ? 'row' : 'column', justifyContent: 'space-between', alignItems: dims.x > 1200 ? 'flex-start' : 'center' }}>
            <div style={{ width: dims.x > 1200 ? '49%' : '90%', display: 'flex', flexDirection: 'column', marginBottom: dims.x > 1200 ? 0 : 10 }}>
              <input style={{ width: '100%', height: 50, backgroundColor: 'white', border: 'none', borderRadius: '5px', fontFamily: 'Poppins', color: colors.dark_blue, outline: 'none', padding: 10, marginBottom: 10 }} placeholder='Prénom' value={firstname} onChange={(e) => setFirstname(e.target.value)}/>
              <input style={{ width: '100%', height: 50, backgroundColor: 'white', border: 'none', borderRadius: '5px', fontFamily: 'Poppins', color: colors.dark_blue, outline: 'none', padding: 10, marginBottom: 10 }} placeholder='Nom' value={lastname} onChange={(e) => setLastname(e.target.value)}/>
              <input style={{ width: '100%', height: 50, backgroundColor: 'white', border: 'none', borderRadius: '5px', fontFamily: 'Poppins', color: colors.dark_blue, outline: 'none', padding: 10 }} value={email} placeholder='Email' onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div style={{ width: dims.x > 1200 ? '49%' : '90%', display: 'flex', flexDirection: 'column' }}>
              <textarea style={{ width: '100%', height: 170, backgroundColor: 'white', border: 'none', borderRadius: '5px', fontFamily: 'Poppins', color: colors.dark_blue, outline: 'none', padding: 10, resize: 'none' }} placeholder='Votre message...' value={message} onChange={(e) => setMessage(e.target.value)}/>
            </div>
          </div>
          <div
            onClick={submit} onKeyPress={submit} role="button" tabIndex="-2"
            style={{ cursor: (!firstname || !lastname || !email || !message) ? 'disabled' : 'pointer', outline: 'none', height: 35, width: 150, backgroundColor: colors.dark_blue, fontFamily: 'Poppins', color: colors.white, fontWeight: 'bold', fontSize: 16, textTransform: 'uppercase', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: dims.x > 1200 ? 10 : 30, borderRadius: 5, opacity: (!firstname || !lastname || !email || !message) ? 0.5 : 1 }}
          >
            {!loading ? 'Envoyer' : <Loader
              type="ThreeDots"
              color={colors.white}
              height={10}
              width={70}
              timeout={0}
            />}
          </div>
        </form>
      </div>
      {dims.x > 1200 && <div style={{ position: 'absolute', top: 322, right: 430}}>
        <Nuage2 />
      </div>}
      {dims.x > 1200 && <div style={{ position: 'absolute', top: 712, right: 130}}>
        <OmbreMec />
      </div>}
      {dims.x > 1200 && <div style={{ position: 'absolute', top: 720, left: 165}}>
        <OmbreMeuf />
      </div>}
      {dims.x > 1200 && <div style={{ position: 'absolute', top: 380, left: 100}}>
        <Nuage1 />
      </div>}
      {dims.x > 1200 && <div style={{ position: 'absolute', top: 350, right: 100}}>
        <Nuage4 />
      </div>}
      {dims.x > 1200 && <div style={{ width: '100%', position: 'absolute', right: 0, top: 0, zIndex: -1 }}>
        <Fond09 />
      </div>}
    </div>
  )
}
